<template>
  <div>
    <div class="column is-12 has-padding-top-5">
        <p class="title is-pulled-left">
          Bem vindo ao projeto Atena!
        </p>
        <div v-if="!exibeCarrocel" class="columns is-mobile is-centered is-pulled-left">
            <div class="column is-12-mobile is-9-desktop">
              <img src="/image/colorida.png">
            </div>
        </div>
    </div>
    <div v-if="exibeCarrocel">
      <div class="column  is-12 is-pulled-left">
        <p>Como destaques temos Quatro módulos para você. No menu, temos muito mais, confira!</p>
      </div>
      <div class="column  is-12 is-pulled-left carrocel">
        <agile
          :autoplay="nextPrevHovered ? false : true"
          :slides-to-show="3"
          :dots="true"
          :speed="2000"
          :autoplaySpeed="5000"
          :pauseOnDotsHover="true">
            <router-link to="/migracoes" class="has-margin-top-10">
              <a href="" class="db" @mouseover="mouseOver" @mouseleave="mouseLeave">
                <div class="img">
                  <img src="../../public/image/db.png">
                </div>
                <div class="h1">
                  <h1>MS-MIGRAÇÕES</h1>
                </div>
              </a>
            </router-link>
            <router-link to="/msdfe" class="has-padding-10">
              <a href="" class="dfe" @mouseover="mouseOver" @mouseleave="mouseLeave">
                <div class="img">
                  <img src="../../public/image/dfe.jpg">
                </div>
                <div class="h1">
                  <h1>MS-DFe</h1>
                </div>
              </a>
            </router-link>
            <router-link to="/msmercadopago" class="has-padding-10">
              <a href="" class="mercadopago" @mouseover="mouseOver" @mouseleave="mouseLeave">
                <div class="img">
                  <img src="../../public/image/mercado-pago-logo.png">
                </div>
                <div class="h1">
                  <h1>MS-Mercado Pago</h1>
                </div>
              </a>
            </router-link>
            <router-link to="/mspix" class="has-padding-10">
              <a href="" class="pix" @mouseover="mouseOver" @mouseleave="mouseLeave">
                <div class="img">
                  <img src="../../public/image/pix.png">
                </div>
                <div class="h1">
                  <h1>MS-Pix</h1>
                </div>
              </a>
            </router-link>
            <template slot="prevButton"><i class="fas fa-chevron-left fa-lg is-clickable" aria-hidden="true" @mouseover="entrando()"  @mouseleave="saindo()" title="Anterior" /></template>
            <template slot="nextButton"><i class="fas fa-chevron-right fa-lg is-clickable" aria-hidden="true" @mouseover="entrando()" @mouseleave="saindo()" title="Próximo" /></template>
        </agile>
      </div>
    </div>
  </div>
</template>

<script>
import { VueAgile } from 'vue-agile'

export default {
  components: {
    agile: VueAgile
  },
  data() {
    return  {
      nextPrevHovered: false
    }
  },
  computed: {
    exibeCarrocel: function () {
      return process.env.VUE_APP_AMBIENTE === 'DEV'
    }
  },
  methods: {
    entrando() {
      this.nextPrevHovered = true
    },
    saindo(){
      this.nextPrevHovered = false
    },
    mouseOver() {
      document.querySelector('.db').style.opacity = '0.5';
      document.querySelector('.dfe').style.opacity = '0.5';
      document.querySelector('.pix').style.opacity = '0.5';
      document.querySelector('.mercadopago').style.opacity = '0.5';
    },
    mouseLeave() {
      document.querySelector('.db').style.opacity = '1';
      document.querySelector('.dfe').style.opacity = '1';
      document.querySelector('.pix').style.opacity = '1';
      document.querySelector('.mercadopago').style.opacity = '1';
    }
  }
};
</script>

<style lang="scss">
@import url('../comum/styles/agile.scss');

.img-container {
  position: relative;
  overflow: hidden;
  float: left;
  img {
    height:auto;
    width: 100%;
    float: left;
  }
}

.db, .dfe, .pix, .mercadopago {
  height: 300px;
  width: 400px;
  display: inline-table;
  padding: 20px;
  margin: 10px;
  text-align: center;
  .img {
    height: 180px;
    width: 380px;
  }
  .h1 {
    font-size: 2rem;
    font-weight: 500;
    color: #3273dc;
    width: inherit;
    position: absolute;
    bottom: 50px;
  }
}

.pix, .mercadopago {
  img {
    width: inherit;
    height: auto;
  }
}

.db, .dfe {
  img {
    width: auto;
    height: inherit;
  }
}

.carrocel {
  text-align: center;
  justify-content: center;
  height: calc(100vh - 300px);
  margin-top: 25px;
}

.db:hover, .dfe:hover, .pix:hover, .mercadopago:hover {
  opacity: 1 !important;
  transform: scale(1.02);
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  border-radius: 7px;
  div {
    bottom: 40px;
  }
  
}

.agile__actions {
  margin-top: -400px;
  .agile__nav-button {
    color: #2e4283b6 !important;
    background-color: transparent;
    border: none;
    font-size: 25px;
    font-weight: 700;
    z-index: 2;
  }
  .agile__nav-button{
    i:hover{
      transform: scale(1.1);
      color: #2e4283 !important;
    }
  }
  .agile__dot {
    margin-top: 400px;
    button {
      border: none;
      border-radius: 50px;
      height: 10px;
      background-color: rgb(163, 162, 162);
      margin: 6px;
    }
  }
  .agile__dot--current{
    button {
      background-color: #2e4283 !important
    }
  }

}

</style>