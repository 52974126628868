const ALTERDATA_BASE_CDN = 'https://cdn-erpforme.alterdata.com.br';
const MICROFRONTS_CDN = `${ALTERDATA_BASE_CDN}/nimbus/atena/mf`;
const CDN_ENV_FOLDER = process.env.VUE_APP_AMBIENTE;

const cdnComponents = [
  {
    name: 'atena-delivery',
    cdn: `${MICROFRONTS_CDN}/mf-atena-delivery/${CDN_ENV_FOLDER?.toLowerCase()}/mf-atena-delivery.umd.cjs`,
  },
];

export { cdnComponents };
