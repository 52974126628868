const checkExistsComponentRegistered = (cdn) => {
  const scripts = document.getElementsByTagName('script');

  for (const script of scripts) {
    if (script?.src === cdn) return true;
  }

  return false;
};

const registerCdnComponentByPath = (cdn) => {
  if (!checkExistsComponentRegistered(cdn)) {
    const element = document.createElement('script');
    element.src = cdn;

    document.getElementsByTagName('head')[0].appendChild(element);
  }
};

const registerCdnComponents = (components) => {
  console.log(components);
  
  for (const component of components) {
    if (!checkExistsComponentRegistered(component?.cdn)) {
      const element = document.createElement('script');
      element.src = component?.cdn;

      document.getElementsByTagName('head')[0].appendChild(element);
    }
  }
};

export { registerCdnComponents, registerCdnComponentByPath };
