<template>
  <div id="app">
    <div class="columns">
      <div class="column">
        <cabecalho />
      </div>
    </div>
    <div class="column main">
        <router-view />
    </div>
    <rodape />
    <div class="modal" :class="{'is-active': !autenticando}">
      <div class="modal-background"></div>
      <div class="modal-card modal-load">
        <div class="field">
          <div class="control modal-load">
            <div class="loader-wrapper is-active">
            <span class="is-size-3 column is-5">{{'Autenticando'}}</span>
              <div class="loader is-loading has-margin-right-20"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cabecalho from "@/components/Cabecalho.vue";
import Rodape from "@/components/Rodape.vue";
import { cdnComponents } from '@/helpers/cdn-components.helper';
import { registerCdnComponents } from '@/utils/component-register.util';
require("./comum/styles/main.scss")

export default {
  components: {
    Cabecalho,
    Rodape
  },
  computed: {
    autenticando() {
      return this.$store.state.autenticado
    }
  },
  created() {
    registerCdnComponents(cdnComponents);
    document.addEventListener('ce-alt-sso-token', event => {
      if(event.detail) {
        sessionStorage.setItem('autheticationToken', event.detail);
        this.$store.commit('autenticado', true)
      }
    }, false);

    document.addEventListener('ce-alt-sso-userprofile', event => {
      sessionStorage.setItem('userprofile', JSON.stringify(event.detail));
      this.$store.commit('registrarUsuarioLogado', event.detail.username)
    }, false);
  }
};
</script>

<style lang="scss">
  html {
    background-image: linear-gradient(to right bottom, #c6d7ff, #d3deff, #e0e5ff, #eaecff, #f4f4ff, #f8f8ff, #fcfbff, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff);
  }
   .modal-load {
    height: 200px;
    width: 550px !important;
  }
  
  .loader-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #fff;
    opacity: 10;
    z-index: -1;
    transition: opacity .3s;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
  
    .loader {
      height: 180px;
      width: 180px;
    }
  
    &.is-active {
      opacity: 1;
      z-index: 1;
    }
  }

  .main {
    height: calc(100% - 40px);
    width: 100%;
    float: left;
    min-height: calc(100vh - 155px);
  }

  span {
    user-select: none;
    -webkit-user-select:none;
  }
  @import "bulma/bulma.sass";
  @import "bulma-helpers/bulma-helpers.sass";
  @import '~@fortawesome/fontawesome-free/css/all.css';
</style>

